import React from 'react';
import ProjectCard from './ProjectCard';

// Screenshots
import MRtechScreenshot from '../images/mrtech_screenshot.png';
import EVRentalScreenshot from '../images/ev_rental_screenshot.png';
import FutScoreScreenshot1 from '../images/futscore_screenshot_1.png';
import FutScoreScreenshot2 from '../images/futscore_screenshot_2.png';
import FutScoreScreenshot3 from '../images/futscore_screenshot_3.png';
import FutScoreScreenshot4 from '../images/futscore_screenshot_4.png';
import Bloxbuster1 from '../images/bloxbuster_screenshot_1.png';
import Bloxbuster2 from '../images/bloxbuster_screenshot_2.png';
import Bloxbuster3 from '../images/bloxbuster_screenshot_3.png';

// Logos
import TypeScriptLogo from '../images/typescript_logo.png';
import ReactJSLogo from '../images/react_logo.png';
import TailwindCSSLogo from '../images/tailwind_logo.png';
import NodeJSLogo from '../images/node-js_logo.png';
import MongoDBLogo from '../images/mongodb_logo.png';
import SemanticUILogo from '../images/semantic_ui_logo.png';
import FlaskLogo from '../images/just_flask_logo.png';
import RedisLogo from '../images/redis_logo.png';

const projects = [
	{
		name: "MRtech",
		desc: `MRtech is an online electronics shop that sells PC parts and components. The website showcases a
		       selection of products with an intuitive interface allowing users to browse and purchase their favourite tech products.`,
		tech_stack: [
			{ name: "TypeScript", img: TypeScriptLogo},
			{ name: "ReactJS", img: ReactJSLogo },
			{ name: "Tailwind CSS", img: TailwindCSSLogo },
			{ name: "Flask", img: FlaskLogo},
			{ name: "MongoDB", img: MongoDBLogo},
			{ name: "Redis", img: RedisLogo},
		],
		imgs: [MRtechScreenshot],
		links: { github: "https://github.com/MiladRez/mrtech", demo: "https://mrtech-site.vercel.app/" },
		slideshowOrientation: "scrollDown"
	},
	{
		name: "EV Rental",
		desc: `EV Rental is an online platform that streamlines the process of renting an electric vehicle
		 	   with a focus on customer experience. The website provides an easy-to-use interface for browsing,
			   comparing, and booking EVs from a wide selection of the latest vehicles in the market.`,
		tech_stack: [
			{ name: "ReactJS", img: ReactJSLogo},
			{ name: "Tailwind CSS", img: TailwindCSSLogo}
		],
		imgs: [EVRentalScreenshot],
		links: { github: "https://github.com/MiladRez/EV-Rental", demo: "https://ev-rental.netlify.app/" },
		slideshowOrientation: "scrollDown"
	},
	{
		name: "FutScore",
		desc: `Web application that displays live match scores of football matches around the globe and keeps 
		       track of user's favourite leagues and teams.`,
		tech_stack: [
			{ name: "NodeJS", img: NodeJSLogo },
			{ name: "MongoDB", img: MongoDBLogo },
			{ name: "ReactJS", img: ReactJSLogo },
			{ name: "Semantic UI", img: SemanticUILogo },

		],
		imgs: [FutScoreScreenshot1, FutScoreScreenshot2, FutScoreScreenshot3, FutScoreScreenshot4],
		links: { github: "https://github.com/MiladRez/FutScore", demo: "https://futscore.netlify.app/" },
		slideshowOrientation: "scrollRight"
	},
	{
		name: "Bloxbuster",
		desc: `Web application that displays a library of new film releases and popular films and allows users
			   to track, rate, and review the films they've watched.`,
		tech_stack: [
			{ name: "ReactJS", img: ReactJSLogo },
			{ name: "Semantic UI", img: SemanticUILogo }
		],
		imgs: [Bloxbuster1, Bloxbuster2, Bloxbuster3],
		links: { github: "https://github.com/MiladRez/Bloxbuster", demo: "https://bloxbuster.netlify.app/" },
		slideshowOrientation: "scrollRight"
	},
]

function MyProjects() {
	return (
		<section className='relative flex flex-col items-center bg-stone-100 dark:bg-gray-900 py-20 big:py-36 px-12'>
			<span id='portfolio' className='absolute top-[-4rem] xs:top-[0rem]'></span>
			<h1 className='text-2xl big:text-3xl font-semibold text-blue-500 pb-8 sm:pb-16'>Portfolio</h1>
			<div className='grid gap-10 sm:gap-20'>
				{projects.map((project, index) => {
					return <ProjectCard key={index} project={project} projectNumber={index} />
				})}
			</div>
			
		</section>
	)
}

export default MyProjects;
import React from 'react';

function TechStackTools({ tool1, tool2 }) {
	return (
		<div className='flex gap-2 hover:scale-[1.1] transition-all duration-200 transform-gpu'>
			<div className='flex flex-col items-center gap-2'>
				<img src={ tool1.img } alt='tool1' title={ tool1.name } className='w-16 h-16 rounded-xl' />
				<p className='text-xs dark:text-white'>{ tool1.name }</p>
			</div>
			<div className='flex flex-col items-center gap-2'>
				<img src={ tool2.img } alt='tool2' title={ tool2.name } className='w-16 h-16 rounded-xl' />
				<p className='text-xs dark:text-white'>{ tool2.name }</p>
			</div>
		</div>
	)
}

export default TechStackTools;